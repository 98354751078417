import { Tabs, Typography } from "antd";

import { GET_ADMIN_BID_DETAIL_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import CargoInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/CargoInfo";

import MarginQuotationDesc from "./MarginQuotationDesc";

const { Title } = Typography;

const { TabPane } = Tabs;

const BidDetailTabs = ({
  bidDetail,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
}) => {
  if (bidDetail.serviceType === "consolidation") {
    return (
      <Tabs defaultActiveKey="1">
        {bidDetail.quotationsUser.length > 0 && (
          <TabPane tab="확정 견적" key="2">
            <MarginQuotationDesc quotations={bidDetail.quotationsUser[0]} />
          </TabPane>
        )}
      </Tabs>
    );
  }
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="화물정보 및 상세" key="1">
        <CargoInfo bidDetail={bidDetail} />
      </TabPane>

      {bidDetail.quotationsUser.length > 0 && (
        <TabPane tab="확정 견적" key="2">
          <MarginQuotationDesc quotations={bidDetail.quotationsUser[0]} />
        </TabPane>
      )}
    </Tabs>
  );
};

export default BidDetailTabs;
