import React from "react";
import ReactDOM from "react-dom";

import "@sellernote/_shared/src/i18n/initI18nForCRA";

import { OverlayStackProvider } from "@sellernote/_shared/src/context/overlayStack";
import { initSentry } from "@sellernote/_shared/src/services/sentry/sentryForCRA";
import { SnackbarProvider } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar/SnackbarProvider";

import App from "./App";

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <OverlayStackProvider>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </OverlayStackProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
