import { ChangeEvent, useCallback, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Input, Typography } from "antd";

import CUSTOMS_ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_USER_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

const { Text } = Typography;

const ChangePassword = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const [password, setPassword] = useState("");

  const { mutate: changeCustomsAdminUserPassword } =
    CUSTOMS_ADMIN_USER_QUERY.useChangeCustomsAdminUserPassword();

  const handleCustomsAdminUserPasswordChange = useCallback(() => {
    changeCustomsAdminUserPassword(
      {
        password,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("비밀번호를 변경했습니다.");
        },
      }
    );
  }, [changeCustomsAdminUserPassword, handleSnackbarOpen, password]);

  const handlePasswordInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  return (
    <Layout breadcrumbs={["비밀번호"]} title={"비밀번호 수정"}>
      <Text strong>변경 비밀번호 :</Text>

      <Input
        type="password"
        style={{ width: "300px" }}
        value={password}
        onChange={handlePasswordInputChange}
        allowClear
      />

      <Button onClick={handleCustomsAdminUserPasswordChange} type="primary">
        <SaveOutlined /> 수정
      </Button>
    </Layout>
  );
};

export default ChangePassword;
