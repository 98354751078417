import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { PartnerTrelloList } from "@sellernote/_shared/src/types/forwarding/trello";

import TrelloCardList from "../../_components/list/TrelloCardList";
import ListHeadFilter from "../../_components/ListHeadFilter";
import useCommonTrelloListLogic from "../../_hooks/useCommonTrelloListLogic";
import usePartnerCardListData from "../../_hooks/usePartnerCardListData";
import PartnerAdminTrelloDetail from "./DetailModal";

const PartnerGeneralAndConsolidationTrello = ({
  trelloListData,
  adminData,
}: {
  trelloListData: PartnerTrelloList[];
  adminData: ForwardingAdminUserListItem[];
}) => {
  const {
    sessionStorageTrelloIssueFilter,
    showsTrelloDetailModal,
    bidId,
    handleTrelloCardClick,
    salesNameList,
    handleSessionStorageTrelloIssueFilterChange,
    setShowsTrelloDetailModal,
  } = useCommonTrelloListLogic({ adminData });

  const { partnerCardListData } = usePartnerCardListData({
    trelloListData,
    handleTrelloCardClick,
    sessionStorageTrelloIssueFilter,
  });

  return (
    <div>
      <ListHeadFilter
        salesNameList={salesNameList ?? []}
        sessionStorageTrelloIssueFilter={sessionStorageTrelloIssueFilter}
        onSessionStorageTrelloIssueFilterChange={
          handleSessionStorageTrelloIssueFilterChange
        }
      />

      <TrelloCardList cardListData={partnerCardListData} />

      {showsTrelloDetailModal && (
        <PartnerAdminTrelloDetail
          showModal={showsTrelloDetailModal}
          setShowModal={setShowsTrelloDetailModal}
          bidId={bidId}
        />
      )}
    </div>
  );
};

export default PartnerGeneralAndConsolidationTrello;
