import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Modal, Spin } from "antd";
import { useSetRecoilState } from "recoil";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY, {
  CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { useWindowResize } from "@sellernote/_shared/src/utils/common/hook";

import ShipmentCommentList from "../../../TrelloDetail/components/ShipmentCommentList";

import BasicInfo from "../../../_components/detail/BasicInfo";
import PurchaseManagement from "../../../_components/detail/PurchaseManagement";
import RegisterShipSchedule from "../../../_components/detail/RegisterShipSchedule";
import SalesManagement from "../../../_components/detail/SalesManagement";
import UploadDocuments from "../../../_components/detail/UploadDocuments";

const DetailModal = ({
  showModal,
  setShowModal,
  bidId,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  bidId: number | undefined;
}) => {
  const queryClient = useQueryClient();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [windowWidth] = useWindowResize();

  // 모달의 넓이를 최대한 넓게 해달라는 요청 반영
  const modalWidth = windowWidth - 50;

  const { data: trelloDetailData } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useGetCustomsTrelloBidDetail({
      bidId,
    });

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: trelloDetailData?.locale,
  });

  const handleCloseModal = useCallback(() => {
    setShowModal(false);

    queryClient.invalidateQueries(
      CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.customsTrelloBidList()
    );
  }, [queryClient, setShowModal]);

  useEffect(() => {
    setTrelloCardId(undefined);
  }, [setTrelloCardId]);

  if (!trelloDetailData || !exchangeRateList) {
    return (
      <Modal
        destroyOnClose={true}
        style={{ fontFamily: "Roboto" }}
        bodyStyle={{ height: 800, overflow: "scroll" }}
        visible={showModal}
        width={modalWidth}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Spin />
      </Modal>
    );
  }

  return (
    <Modal
      destroyOnClose={true}
      style={{ fontFamily: "Roboto" }}
      bodyStyle={{ height: 800, overflow: "scroll" }}
      visible={showModal}
      width={modalWidth}
      onCancel={handleCloseModal}
      footer={null}
    >
      <BasicInfo trelloDetail={trelloDetailData} />

      <RegisterShipSchedule
        bidId={trelloDetailData.id}
        management={trelloDetailData.management}
        trelloDetail={trelloDetailData}
        region={trelloDetailData.locale}
      />

      <UploadDocuments trelloDetail={trelloDetailData} />

      <SalesManagement trelloDetailData={trelloDetailData} />

      <PurchaseManagement
        trelloDetailData={trelloDetailData}
        exchangeRateList={exchangeRateList}
      />

      <ShipmentCommentList trelloDetailData={trelloDetailData} />
    </Modal>
  );
};

export default DetailModal;
