import { useCallback } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions } from "antd";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { QuotationsUser } from "@sellernote/_shared/src/types/forwarding/bid";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeQuotationsStatusToKr } from "@sellernote/_shared/src/utils/forwarding/bid";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

const MarginQuotationDesc = ({
  quotations,
}: {
  quotations: QuotationsUser;
}) => {
  const { handleSnackbarOpen } = useSnackbar();

  const quotationsArray = [{ ...quotations }];

  const { mutate: downloadBidQuotation } =
    ADMIN_BID_QUERY.useDownloadBidQuotation(quotations.bidId);

  const handleBidQuotationDownload = useCallback(() => {
    downloadBidQuotation(
      {},
      {
        onSuccess: ({ data: successData }) => {
          window.location.href = successData.preSignedUrl.url;
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  }, [downloadBidQuotation, handleSnackbarOpen]);

  return (
    <div>
      <Card hoverable={true} style={{ cursor: "pointer" }}>
        <Descriptions
          contentStyle={{ fontWeight: "bold" }}
          column={4}
          title={
            <div style={{ fontWeight: "bold", fontSize: 20 }}>확정견적</div>
          }
          extra={
            <Button
              style={{
                marginLeft: 8,
                width: 154,
                height: 32,
                padding: 0,
              }}
              type="primary"
              onClick={handleBidQuotationDownload}
            >
              견적서 다운로드
              <DownloadOutlined />
            </Button>
          }
        >
          {quotationsArray.map((v) => {
            return (
              <>
                <Descriptions.Item label="견적 담당자">
                  셀러노트
                </Descriptions.Item>

                <Descriptions.Item label="제출가">{`${toThousandUnitFormat(
                  v.totalPrice
                )}원`}</Descriptions.Item>

                <Descriptions.Item label="제출일자">
                  {new Date(v.createdAt).toLocaleDateString()}
                </Descriptions.Item>

                <Descriptions.Item label="유효기간">
                  {new Date(v.expiredAt).toLocaleDateString()}
                </Descriptions.Item>

                <Descriptions.Item label="상태">
                  {changeQuotationsStatusToKr(v.status)}
                </Descriptions.Item>
              </>
            );
          })}
        </Descriptions>
      </Card>
    </div>
  );
};

export default MarginQuotationDesc;
