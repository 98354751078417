import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ErrorBoundary from "@sellernote/_sds-v1/src/components/error/ErrorBoundary";
import PageNotFoundForCRA from "@sellernote/_sds-v1/src/components/error/PageNotFoundForCRA";

import Home from "pages";
import Bid from "pages/bid";
import BidDetail from "pages/bid/detail";
import ChangePassword from "pages/change-password";
import Login from "pages/login";
import TrelloListByServiceType from "pages/trello/:serviceType";

function AppRouter({ children }: { children: React.ReactNode }) {
  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          <Route path="/" component={Home} exact />

          <Route path="/login" component={Login} exact />

          <Route path="/change-password" component={ChangePassword} exact />

          <Route path="/bid" component={Bid} exact />

          <Route path="/bid/detail/:id" component={BidDetail} exact />

          <Route
            path="/trello/:serviceType"
            component={TrelloListByServiceType}
            exact
          />

          <Route path="*" component={PageNotFoundForCRA} />
        </Switch>

        {children}
      </ErrorBoundary>
    </Router>
  );
}

export default AppRouter;
