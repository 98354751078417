import { useParams } from "react-router-dom";
import { useAtomValue } from "jotai";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import CUSTOMS_ADMIN_TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import { TrelloCategory } from "@sellernote/_shared/src/types/forwarding/trello";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import { FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/trello";
import PartnerGeneralAndConsolidationTrello from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/trello/partner/GeneralAndConsolidation";

/**
 * partner-admin 의 serviceType 은 general, consolidation 두가지.
 */
const TrelloListByServiceType = () => {
  const { serviceType } = useParams<{ serviceType: TrelloCategory }>();

  const fetchTrelloListParams = useAtomValue(
    FORWARDING_ADMIN_TRELLO_JOTAI_SELECTORS.FETCH_TRELLO_LIST_PARAMS
  );
  /**
   * 관세사 어드민 트렐로 카드 요청엔 Region 파라미터 제거
   */
  const { region, corpSizeType, blKind, ...trelloParams } =
    fetchTrelloListParams;

  const { data: trelloBidList } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useGetCustomsTrelloBidList(
      {
        ...trelloParams,
        status: "inProgress",
        perPage: 300,
        serviceType:
          serviceType === "consolidation" ? "consolidation" : undefined,
      },
      true
    );

  const { data: trelloBidFinishedList } =
    CUSTOMS_ADMIN_TRELLO_BID_QUERY.useGetCustomsTrelloBidFinishedList(
      {
        ...trelloParams,
        status: "finished",
        projectStatus: "finished",
        perPage: 200,
        serviceType:
          serviceType === "consolidation" ? "consolidation" : undefined,
      },
      true
    );

  const { adminUserList } = ADMIN_USER_QUERY.useGetAdminUserList();

  if (!trelloBidFinishedList || !trelloBidList || !adminUserList) {
    return (
      <Layout>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout>
      <PartnerGeneralAndConsolidationTrello
        trelloListData={trelloBidList.concat(trelloBidFinishedList)}
        adminData={adminUserList}
      />
    </Layout>
  );
};

export default withRequireAuth(TrelloListByServiceType);
